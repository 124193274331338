<template>
    <main class="mb-5 pb-5">
        <div class="my-10 pb-10">
            <h1>
                Detalles de cliente
                <strong class="text-primary"> # {{ itemComponent.id }} </strong>
            </h1>
        </div>
        <vs-navbar
            v-if="id"
            id="tabs-orders"
            v-model="activeTab"
            center-collapsed
            not-line
            class="position-static"
        >
            <template #left>
                <vs-navbar-item id="home">
                    <router-link
                        to="/app/customers"
                        class="rounded-circle bg-info d-flex justify-content-center align-items-center p-2"
                    >
                        <span>🏠</span>
                    </router-link>
                </vs-navbar-item>
                <vs-navbar-item
                    id="general"
                    :active="['general', 'home'].includes(activeTab)"
                    class="px-2 px-md-3"
                >
                    <div class="d-flex">
                        <span class="d-md-block d-none">
                            Información general
                        </span>
                        <span>&nbsp;⚙</span>
                    </div>
                </vs-navbar-item>
                <vs-navbar-item
                    v-if="id"
                    id="security"
                    :active="activeTab === 'security'"
                    class="px-2 px-md-3"
                >
                    <div class="d-flex">
                        <span class="d-md-block d-none"> Seguridad </span>
                        <span>&nbsp;🔐</span>
                    </div>
                </vs-navbar-item>
                <vs-navbar-item
                    v-if="$ability.hasScope('location:List')"
                    id="locations"
                    :active="activeTab === 'locations'"
                    class="px-2 px-md-3"
                >
                    <div class="d-flex">
                        <span class="d-md-block d-none"> Direcciones </span>
                        <span>&nbsp;🌎</span>
                    </div>
                </vs-navbar-item>
                <vs-navbar-item
                    v-if="$ability.hasScope('customer:ListOrders')"
                    id="orders"
                    :active="activeTab === 'orders'"
                    class="px-2 px-md-3"
                >
                    <div class="d-flex">
                        <span class="d-md-block d-none"> Ordenes </span>
                        <span>&nbsp;🛍️</span>
                    </div>
                </vs-navbar-item>
            </template>
            <template #right>
                <vs-switch
                    v-if="$ability.hasScope('customer:Update')"
                    v-model="enabledUpdate"
                    :class="enabledUpdate ? 'bg-primary' : 'bg-secondary'"
                >
                    🖊
                </vs-switch>
            </template>
        </vs-navbar>

        <div v-show="['general', 'home'].includes(activeTab)">
            <h3 class="text-success my-3">Datos básicos 👩‍🦱</h3>
            <div class="d-flex flex-wrap mt-3">
                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <vs-input
                        v-model="itemComponent.firstName"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Nombres"
                        class="w-100 opacity-false"
                        :disabled="!enabledUpdate"
                    />
                </div>
                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <vs-input
                        v-model="itemComponent.lastName"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Apellidos"
                        class="w-100 opacity-false"
                        :disabled="!enabledUpdate"
                    />
                </div>
                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <identification-type-selector
                        v-model="itemComponent.identificationType"
                        :enabled="enabledUpdate"
                    />
                </div>

                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <vs-input
                        v-model="itemComponent.identificationNumber"
                        border
                        shadow
                        :state="stateEnabled"
                        class="w-100 opacity-false"
                        label="Número de Identificación"
                        :disabled="!enabledUpdate"
                    />
                </div>

                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <vs-input
                        v-model="itemComponent.phone"
                        border
                        shadow
                        :state="stateEnabled"
                        class="w-100 opacity-false"
                        label="Celular de contacto"
                        type="number"
                        :disabled="!enabledUpdate"
                    />
                </div>

                <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                    <vs-input
                        v-model="itemComponent.email"
                        border
                        shadow
                        :state="stateEnabled"
                        class="w-100 opacity-false"
                        label="Correo electrónico"
                        disabled
                    />
                </div>
            </div>
            <div class="d-flex justify-content-center my-4">
                <vs-button
                    :disabled="!enabledUpdate"
                    @click="updateBasicDataCustomer"
                >
                    Actualizar información básica
                </vs-button>
            </div>
        </div>

        <div v-if="activeTab === 'locations'">
            <h3 class="text-success my-3">Direcciones registradas 🌎</h3>

            <div v-if="locationsUser.length" class="d-flex flex-wrap">
                <vs-card-group>
                    <vs-card
                        v-for="(item, index) in locationsUser"
                        :key="index"
                    >
                        <template #title>
                            <div
                                class="d-flex justify-content-between align-items-center"
                            >
                                <span class="h4">
                                    {{ item.address }}
                                </span>
                            </div>
                        </template>
                        <template #text>
                            <div class="w-100 d-flex flex-column">
                                <div class="d-flex flex-column my-2">
                                    <strong>
                                        Nombre de la persona que recibe
                                    </strong>
                                    <span class="text-uppercase mt-2 ml-2">
                                        {{ item.receiverName }}
                                    </span>
                                </div>
                                <div class="d-flex flex-column my-2">
                                    <strong>Celular persona que recibe </strong>
                                    <span class="mt-2 ml-2">
                                        {{ item.receiverPhone }}
                                    </span>
                                </div>
                                <div class="d-flex flex-column my-2">
                                    <strong>Ciudad </strong>
                                    <span class="mt-2 ml-2">
                                        {{ item.city }} ({{ item.cityCode }})
                                    </span>
                                </div>
                                <div class="d-flex flex-column my-2">
                                    <strong>Dirección </strong>
                                    <span class="mt-2 ml-2">
                                        {{ item.address }} / Barrio
                                        {{ item.neighborhood }}
                                    </span>
                                </div>
                            </div>
                        </template>
                    </vs-card>
                </vs-card-group>
            </div>

            <div v-else>
                <strong>
                    No se han registrado direcciones para el cliente.
                </strong>
            </div>
        </div>

        <div v-if="activeTab === 'security'">
            <h3 class="text-success my-3">Seguridad 🔐</h3>
            <div class="my-4">
                En esta sección puedes reestablecer la contraseña.
            </div>
        </div>
        <div v-if="activeTab === 'orders'">
            <h3 class="text-success my-3">Ordenes 💳</h3>
            <div class="my-4">
                <b-table
                    v-show="loadedOrder"
                    ref="selectableTable"
                    :fields="$data.$fields"
                    :items="ordersUser"
                    :dark="activeDark"
                    :per-page="ordersUser.length"
                    responsive
                    outlined
                    no-border-collapse
                    hover
                    class="col-12"
                >
                    <template #cell(id)="data">
                        <div class="d-flex align-items-center">
                            <a
                                :href="`/app/orders/detail/${data.item.id}`"
                                target="_blank"
                                rel="noopener"
                                class="text-center font-weight-normal btn btn-primary p-1"
                            >
                                <span> {{ data.item.id }}</span>
                            </a>
                        </div>
                    </template>
                    <template #cell(deliveryOptionMessage)="data">
                        <small
                            class="text-center"
                            :class="
                                data.item.deliveryOption === 'home'
                                    ? 'text-info'
                                    : ''
                            "
                        >
                            {{ data.item.deliveryOptionMessage }}
                        </small>
                    </template>
                    <template #cell(paymentMethodMessage)="data">
                        <small class="text-center">
                            {{ data.item.paymentMethodMessage }}
                        </small>
                    </template>
                    <template #cell(total)="data">
                        <small class="text-center font-weight-normal">
                            {{ data.item.total | money }}
                        </small>
                    </template>
                    <template #cell(createdAt)="data">
                        <small class="text-center font-weight-normal">
                            {{ data.item.createdAt | date }}
                        </small>
                    </template>
                    <template #cell(updatedAt)="data">
                        <small class="text-center font-weight-normal">
                            {{ data.item.updatedAt | date }}
                        </small>
                    </template>
                </b-table>
                <div v-if="!ordersUser.length" class="col-11 mx-auto mt-4">
                    <strong>
                        Nuestro sistema NO encuentra resultados. Intenta
                        modificar los filtros de búsqueda.
                    </strong>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import IdentificationTypeSelector from "@/components/users/IdentificationTypeSelector.vue";

export default {
    name: "DetailCustomers",
    components: {
        IdentificationTypeSelector
    },
    data: () => ({
        $fields: [
            { key: "id", label: "ID", sortable: false },
            {
                key: "deliveryOptionMessage",
                label: "Opción de entrega",
                sortable: false
            },
            {
                key: "paymentMethodMessage",
                label: "Método de pago",
                sortable: false
            },
            { key: "total", label: "Total", sortable: true },
            { key: "createdAt", label: "📆 Creación", sortable: true },
            { key: "updatedAt", label: "📆 Actualización", sortable: true }
        ],
        itemComponent: {
            id: "",
            identificationType: "cc",
            identificationNumber: "",
            firstName: "Cargando...",
            lastName: "Cargando...",
            email: "name@doomain.com",
            phone: "..."
        },
        loadedOrder: false,
        loaderInstance: null,
        activeTab: "general",
        enabledUpdate: false,
        isLoading: false,
        ordersUser: [],
        locationsUser: [],
        scopes: [],
        id: ""
    }),
    computed: {
        ...mapState("control", ["activeDark"]),
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        stateEnabled() {
            return this.enabledUpdate ? this.stateInputDark : "";
        }
    },
    watch: {
        activeTab(value) {
            if (value === "orders") {
                this.listOrders();
            }
            if (value === "locations") {
                this.listLocations();
            }
        }
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if ("id" in this.$route.params) {
            if (!(await verifyPageScope("customer:Get", "/app/customers")))
                return;
            this.id = this.$route.params.id;
            await this.getBaseCustomer();
        }
    },
    methods: {
        ...mapActions("customers", [
            "updateCustomer",
            "getCustomerById",
            "listCustomerLocations",
            "listCustomerOrdersPaginated"
        ]),
        async getBaseCustomer() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square" });
            try {
                this.itemComponent = await this.getCustomerById({
                    id: this.id
                });
            } catch (error) {
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app");
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async updateBasicDataCustomer() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square" });
            try {
                await this.updateCustomer(this.itemComponent);
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        async listOrders() {
            this.loaderInstance = this.$vs.loading({ type: "radius" });
            try {
                const { items } = await this.listCustomerOrdersPaginated({
                    page: 1,
                    customerId: this.id,
                    complete: false
                });
                this.ordersUser = items;
                this.loadedOrder = true;
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        },
        async listLocations() {
            this.loaderInstance = this.$vs.loading({ type: "radius" });
            try {
                this.locationsUser = await this.listCustomerLocations({
                    page: 1,
                    customerId: this.id
                });
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        }
    }
};
</script>
